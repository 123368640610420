



















































import { Component, Vue, Ref } from "vue-property-decorator";
import { Route } from "vue-router";
import LoggingHistoryConfigureWrapper from "@/components/knockoutWrappers/LoggingHistoryConfigureWrapper.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import LoggingHistoryGraph from "@/components/LoggingHistoryGraph.vue";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import { LoggingHistoryConfig } from "@/types/loggingHistoryConfig";
import { LogDataFilter } from "@/types/logDataFilter";
import { Vessel } from "@/types/vessel";
import LoggingHistoryClient from "@/Scripts/clients/logging-history-client";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const Vessels = getModule(VesselsModule, Store);
const LogVariables = getModule(LogVariablesModule, Store);

@Component({
  components: {
    LoggingHistoryConfigureWrapper,
    FeatureNotAvailable,
    LoggingHistoryGraph,
  },
  beforeRouteEnter(to: Route, from: Route, next): void {
    function showLoggingHistory(vessel: Vessel | null): boolean {
      return Boolean(vessel?.logDataMaxDate || vessel?.logDataMinDate);
    }
    //Case: switching to a vessel from sidebar where logging history is not a menu item - redirect to 'Info'
    if (showLoggingHistory(Vessels.currentVessel)) {
      next();
    } else {
      next({ name: "Info", replace: true });
    }
  },
})
export default class LoggingHistory extends Vue {
  vessel = Vessels.currentVessel;
  configFilters: LogDataFilter[] = [];
  vesselIdObject = { vesselId: this.vessel?.id };
  configPanel: any;
  panelsOpen: 0 | null = 0;

  @Ref() readonly wrapper!: LoggingHistoryConfigureWrapper;

  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "LoggingHistory");
  }

  onSubmit(configData: LoggingHistoryConfig): void {
    const logVariableIdsGroups = configData.logVariableIds?.split(/\||;/) ?? [];

    //If there are no group graphs, the last element will be an empty string we need to remove
    if (!logVariableIdsGroups[logVariableIdsGroups.length - 1]) {
      logVariableIdsGroups.pop();
    }

    //If there are no distinct graphs, the first element will be an empty string we need to remove
    if (!logVariableIdsGroups[0]) {
      logVariableIdsGroups.shift();
    }

    let fromDate: string;
    let toDate: string;

    if (configData.timeConfigurationType === "AllValues") {
      fromDate = this.vessel ? this.vessel.logDataMinDate : "";
      toDate = this.vessel ? this.vessel.logDataMaxDate : "";
    } else if (configData.timeConfigurationType === "LastVoyage") {
      fromDate = configData.lastVoyage!.startDate;
      toDate = configData.lastVoyage!.endDate;
    } else if (configData.timeConfigurationType === "LastPeriod") {
      const fromDateDate = new Date();

      fromDateDate.setUTCDate(fromDateDate.getUTCDate() - configData.lastNumberDays);
      fromDateDate.setUTCHours(fromDateDate.getUTCHours() - configData.lastNumberHours);
      fromDateDate.setUTCMinutes(fromDateDate.getUTCMinutes() - configData.lastNumberMinutes);

      fromDate = fromDateDate.toISOString();
      toDate = new Date().toISOString();
    } else {
      fromDate = configData.fromDateTime;
      toDate = configData.toDateTime;
    }

    const selectedTimeInMinutes = this.minutesDiff(new Date(fromDate), new Date(toDate));

    const granularity = {
      Auto: this.autoGranularity(selectedTimeInMinutes),
      Raw: "Raw",
      Minute: "Minute",
      Quarter: "QuarterHour",
      Hour: "Hour",
      Day: "Day",
    }[configData.dataInterval];

    const filterMinMax = configData.filterMinMax;
    const filterOutliers = configData.filterOutliers;

    this.configFilters = logVariableIdsGroups.map(logVariableIds => ({
      logVariableIds,
      fromDate,
      toDate,
      granularity,
      filterMinMax,
      filterOutliers,
    }));

    this.panelsOpen = null;
  }

  onUpdateTimePeriod({ fromDateTime, toDateTime }: { fromDateTime: number; toDateTime: number }): void {
    this.wrapper.updateTimePeriod(fromDateTime, toDateTime);
  }

  //Auto will set granularity to the most detailed option available.
  //Using same limits that are already defined in graph-config-data-interval.
  autoGranularity(selectedTimeInMinutes: number): string {
    if (selectedTimeInMinutes <= 1440) {
      return "Raw";
    } else if (selectedTimeInMinutes <= 2880) {
      return "Minute";
    } else if (selectedTimeInMinutes <= 20160) {
      return "QuarterHour";
    } else if (selectedTimeInMinutes <= 129600) {
      return "Hour";
    }

    return "Day";
  }

  minutesDiff(fromDate: Date, toDate: Date): number {
    const diff = (fromDate.getTime() - toDate.getTime()) / 1000 / 60;

    return Math.abs(Math.round(diff));
  }

  downloadExcel(): void {
    LoggingHistoryClient.DownloadExcel(Vessels.currentVessel?.id);
  }

  downloadCsv(): void {
    LoggingHistoryClient.DownloadCsv(Vessels.currentVessel?.id);
  }

  async created(): Promise<void> {
    await LogVariables.refreshCurrentVesselLogVariables(parseInt(this.$route.params.vesselId));
  }
}
