

















































import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import LogDataClient from "Clients/log-data-client";
import { LogDataFilter } from "@/types/logDataFilter";
import { LogData } from "@/types/logData";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
HighchartsNoData(Highcharts);

const LogVariables = getModule(LogVariablesModule, Store);

@Component({
  components: {
    HighCharts: Chart,
  },
})
export default class LoggingHistoryGraph extends Vue {
  @Prop({ type: Object }) readonly filter?: LogDataFilter;

  @Ref() readonly chart!: any;

  logData: LogData | null = null;
  loadingState: "idle" | "loading" = "idle";
  graphZoomed = false;
  selectedXAxisMin = null;
  selectedXAxisMax = null;
  differenceThreshold = 55;

  get isLoading(): boolean {
    return this.loadingState === "loading";
  }

  get hasLogData(): boolean {
    return this.logData !== null;
  }

  getLogVariableName(index: number): string | undefined {
    const logVariableId = this.logData?.filter.logVariableIds[index];
    if (logVariableId) {
      return LogVariables.currentVesselLogVariables.find(logVariable => logVariable.id === logVariableId)?.displayName;
    }
  }

  getLogVariableDisplayPrecision(index: number): number | undefined {
    const logVariableId = this.logData?.filter.logVariableIds[index];
    if (logVariableId) {
      return LogVariables.currentVesselLogVariables.find(logVariable => logVariable.id === logVariableId)?.displayPrecision;
    }
  }

  @Watch("filter", { immediate: true })
  async onFilterChange(filter: LogDataFilter): Promise<void> {
    this.loadingState = "loading";
    this.graphZoomed = false;

    try {
      this.logData = await LogDataClient.batchFindLogData(filter.logVariableIds, filter.fromDate, filter.toDate, filter.granularity, filter.filterMinMax, filter.filterOutliers);
    } finally {
      this.loadingState = "idle";
    }
  }

  get chartOptions(): unknown {
    return {
      time: {
        useUTC: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        marginTop: 50,
        type: "line",
        zoomType: "x",
        resetZoomButton: {
          theme: {
            display: "none",
          },
        },
        events: {
          //Updates the average value in legend when zooming on the graph
          selection: function (this: any, event: any) {
            setTimeout(() => {
              if (event.resetSelection) {
                this.graphZoomed = false;
              } else {
                this.graphZoomed = true;
              }

              //Custom code to update averge value in label when zooming the graph
              this.selectedXAxisMin = event.target.xAxis[0].min;
              this.selectedXAxisMax = event.target.xAxis[0].max;

              const xAxisMin = Math.ceil(event.target.xAxis[0].min);
              const xAxisMax = Math.ceil(event.target.xAxis[0].max);
              const seriesList = event.target.series;
              const selectedSeriesList = seriesList.map((series: any) => {
                return series.data.filter((point: any) => point.x >= xAxisMin && point.x <= xAxisMax);
              });

              for (const i in selectedSeriesList) {
                seriesList[i].update(
                  {
                    name: `${this.getLogVariableName(i)} ${this.averageValue(i, selectedSeriesList[i])}`,
                  },
                  true
                );
              }
            });
          }.bind(this),
        },
        style: {
          fontFamily: "Arial",
        },
      },
      title: {
        text: "",
      },
      yAxis: this.yAxis,
      xAxis: {
        type: "datetime",
        showEmpty: false,
      },
      series: this.series,
      noData: {
        style: {
          color: "#331714",
        },
      },
      lang: {
        noData: "Found no data for selected time period",
      },
    };
  }

  get series(): any {
    var series = this.logData?.filter.logVariableIds.map((_, index) => ({
      name: this.getLogVariableName(index) + this.averageValue(index),
      lineWidth: 2,
      yAxis: index,
      data: this.dateValuePairs(index),
    }));
    return series;
  }

  dateValuePairs(seriesIndex: number): any {
    return this.logData?.data
      ? Object.entries(this.logData.data).map(([timestamp, pairValues]) => [
          Date.parse(timestamp),
          pairValues[seriesIndex] !== null ? Number(pairValues[seriesIndex].toFixed(this.getLogVariableDisplayPrecision(seriesIndex))) : null,
        ])
      : null;
  }

  yAxisMinMaxValues(seriesIndex: number): { max: number; min: number; unit: string | undefined } | null {
    const yAxisValues = this.logData?.data
      ? Object.entries(this.logData.data).map(([timestamp, pairValues]) => (pairValues[seriesIndex] !== null ? Number(pairValues[seriesIndex].toFixed(2)) : 0))
      : null;
    if (yAxisValues === null) return yAxisValues;
    return {
      min: Math.min(...yAxisValues),
      max: Math.max(...yAxisValues),
      unit: this.logData?.units[seriesIndex].name,
    };
  }

  //Highcharts only provide built in colors for the x-axis, so here we manually set the colors of the y-axis to match.
  get yAxisColors(): string[] {
    return ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9"];
  }

  get yAxis(): any {
    const axes = this.logData?.filter.logVariableIds.map((_, index) => {
      return {
        title: {
          text: "",
          style: { color: this.yAxisColors[index] },
        },
        labels: {
          format: "{value} " + this.logData?.units[index].caption,
          style: { color: this.yAxisColors[index] },
        },
        ...this.yAxisMinMaxValues(index),
      };
    });
    return this.combineCloseAxises(axes);
  }

  combineCloseAxises(axes: any): [] {
    let axesWithSameUnit: any = [];
    let yAxisOffset = 0;
    this.logData?.units.forEach((unit, index) => {
      const yAxesMinMaxValues: any = [];
      axesWithSameUnit = axes.filter((axis: any) => unit.name === axis.unit);
      if (axesWithSameUnit.length >= 2) {
        //  get all min max values from axes with same unit
        axesWithSameUnit.forEach((axis: any) => yAxesMinMaxValues.push(axis?.min, axis?.max));
        // find highest and lowest values
        const highestMaxAndLowestMinValues = { min: Math.min(...yAxesMinMaxValues), max: Math.max(...yAxesMinMaxValues) };
        //  set highest max and lowest min values for axes with the same unit
        axesWithSameUnit.forEach((axis: any) => {
          axis.min = highestMaxAndLowestMinValues.min;
          axis.max = highestMaxAndLowestMinValues.max;
          axis.offset = yAxisOffset;
        });
        if (index > 1) yAxisOffset += 40;
      }
    });
    //  combine axes
    axes = [...new Set([...axes, ...axesWithSameUnit])];
    return axes;
  }

  resetZoom(): void {
    this.chart.chart.zoomOut();
  }

  averageValue(index: number, data?: []): string {
    let counter = 0;
    let value = 0;

    //When zooming the graph, the method is provided with data for the zoomed area only
    if (data) {
      const dataArray = data;

      dataArray.forEach(function (dataEntry: any) {
        if (dataEntry.y !== null) {
          value += dataEntry.y;
          counter++;
        }
      });
    } else {
      const dataArray = this.logData?.data ? Object.values(this.logData.data) : [];

      dataArray.forEach(function (dataEntry) {
        if (dataEntry[index] !== null) {
          value += dataEntry[index];
          counter++;
        }
      });
    }

    return counter > 0
      ? ` (Avg: ${Number((value / counter).toFixed(this.getLogVariableDisplayPrecision(index)))}
                        ${this.logData?.units[index].name})`
      : " (No data)";
  }

  updateTimePeriod(): void {
    this.$emit("updateTimePeriod", { fromDateTime: this.selectedXAxisMin, toDateTime: this.selectedXAxisMax });
  }
}
