import net from "App/net";

var LoggingHistoryClient = function() {
        
};

LoggingHistoryClient.prototype.DownloadExcel = function(vesselId) {
    net.fileDownload("/LoggingHistory/Excel?vesselId=" + vesselId, "GET");
};

LoggingHistoryClient.prototype.DownloadCsv = function(vesselId) {
    net.fileDownload("/LoggingHistory/Csv?vesselId=" + vesselId, "GET");
};

export default new LoggingHistoryClient();
